type ExtendedPropertyKey = (string & {}) | Exclude<PropertyKey, string>;

export function hasKey<T>(obj: T, key: keyof T | ExtendedPropertyKey): key is keyof T {
  return obj != null && Object.prototype.hasOwnProperty.call(obj, key);
}

export function hasKeyExtend<T, U extends ExtendedPropertyKey>(obj: T, key: keyof T | U): obj is T & { [key in U]: unknown } {
  return obj != null && Object.prototype.hasOwnProperty.call(obj, key);
}

export function hasKeys<T>(obj: T, keys: Array<keyof T | ExtendedPropertyKey>): keys is Array<keyof T> {
  return keys.every((key) => obj != null && Object.prototype.hasOwnProperty.call(obj, key));
}

export function hasKeysExtend<T, U extends ExtendedPropertyKey>(obj: T, keys: Array<keyof T | U>): obj is T & { [key in U]: unknown } {
  return keys.every((key) => obj != null && Object.prototype.hasOwnProperty.call(obj, key));
}
