export interface EmulatorPlugin {
  getInfo(): Promise<EmulatorInfo>;
}

export interface EmulatorInfo {
  /** 안드로이드 에뮬레이터로 추정되면 `true` */
  emulatorFound?: boolean;
  /** 안드로이드 루팅 감지 시 `true` */
  rooted?: boolean;
  /**
   * Mac 상에서 실행 중이면 `true`
   *
   * {@link https://developer.apple.com/documentation/foundation/processinfo/3608556-isiosapponmac isiOSAppOnMac} 참고
   */
  isiOSAppOnMac?: boolean;
}
