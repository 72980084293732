import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { AuthService } from '../../services/auth/auth.service';

/** 특수 조건 만족해야만 웹에서 접근 가능하도록 */
export const webGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const authService = inject(AuthService);

  const isWeb = !Capacitor.isNativePlatform();
  const authed = authService.authed;
  const cookieSet = /(^| )wa=1($|;)/.test(document.cookie);

  if (isWeb && !(authed || cookieSet)) {
    const extras = router.getCurrentNavigation()?.extras;

    router.navigate(['/get-app'], {
      skipLocationChange: extras?.skipLocationChange,
      replaceUrl: extras?.replaceUrl,
    });
    return false;
  }
  return true;
};
