export enum APIErrorCode {
  SIGN_UP_REQUIRED = 'sign_up_required',
  ID_VERIFICATION_REQUIRED = 'id_verification_required',
  UPDATE_REQUIRED = 'update_required',
  INSUFFICIENT_POINT_BALANCE = 'insufficient_point_balance',
  INSUFFICIENT_CELEB_POINT_BALANCE = 'insufficient_celeb_point_balance',
  USER_NOT_FOUND = 'user_not_found',
  USER_SUSPENDED = 'user_suspended',
  MEMBERSHIP_REQUIRED = 'membership_required',
  SIGN_UP_RESTRICTED = 'sign_up_restricted',
}
