import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { BehaviorSubject, catchError, defer, firstValueFrom, forkJoin, map, Observable, of } from 'rxjs';
import { UAParser } from 'ua-parser-js';
import { APP_BUILD, APP_VERSION } from '../../../../version';
import { Emulator } from '../../../plugins/emulator';
import { DeviceInfo } from '../../../types/common';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceInfoService extends BaseService {
  readonly deviceInfo$: Observable<DeviceInfo | null>;

  get deviceInfo(): DeviceInfo | null {
    return this.deviceInfoSubject.value;
  }

  private readonly deviceInfoSubject = new BehaviorSubject<DeviceInfo | null>(null);

  constructor() {
    super();

    this.deviceInfo$ = this.deviceInfoSubject.asObservable();
  }

  /** 2번 이상 호출하지 않도록 주의 */
  async init(): Promise<void> {
    const deviceInfo = await firstValueFrom(
      Capacitor.isNativePlatform()
        ? forkJoin({
            capAppInfo: App.getInfo(),
            capDeviceInfo: Device.getInfo(),
            capDeviceId: Device.getId(),
            capEmulatorInfo: Emulator.getInfo(),
          }).pipe(
            map(({ capAppInfo, capDeviceInfo, capDeviceId, capEmulatorInfo }) => {
              const capPlatform = Capacitor.getPlatform();
              const isAndroidApp = capPlatform === 'android';
              const isIosApp = capPlatform === 'ios';
              const deviceInfo: DeviceInfo = {
                appName: isAndroidApp ? 'CelebHere Android' : isIosApp ? 'CelebHere iOS' : undefined,
                appVersion: capAppInfo.version,
                appBuild: capAppInfo.build,
                deviceId: capDeviceId.identifier,
                deviceModel: capDeviceInfo.model,
                platform: isAndroidApp ? 'Android' : isIosApp ? 'iOS' : undefined,
                systemVersion: capDeviceInfo.osVersion,
                emulatorFound: capEmulatorInfo.emulatorFound ? true : undefined,
                rooted: capEmulatorInfo.rooted ? true : undefined,
                isiOSAppOnMac: capEmulatorInfo.isiOSAppOnMac ? true : undefined,
              };
              return deviceInfo;
            }),
            catchError((err) => {
              console.error(err);
              return of(null);
            })
          )
        : defer(() => {
            const parsedUA = new UAParser(navigator.userAgent).getResult();
            const deviceInfo: DeviceInfo = {
              appName: 'CelebHere Web',
              appVersion: APP_VERSION,
              appBuild: APP_BUILD,
              // deviceId: undefined,
              deviceModel: parsedUA.device.model,
              platform: parsedUA.os.name,
              systemVersion: parsedUA.os.version,
            };
            return of(deviceInfo);
          })
    );

    this.deviceInfoSubject.next(deviceInfo);
  }
}
