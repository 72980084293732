import { Injectable, OnDestroy } from '@angular/core';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  EMPTY,
  NEVER,
  Observable,
  Subject,
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  merge,
  of,
  race,
  skip,
  startWith,
  switchMap,
  take,
  tap,
  throwError,
  timer,
} from 'rxjs';
import {
  GetUserListCelebs2QueryParams,
  GetUserListCelebs2Result,
  GetUserListCelebsQueryParams,
  GetUserListCelebsResult,
  GetUserPremiumSubscribersQueryParams,
  GetUserPremiumSubscribersResult,
  GetUserPremiumSubscribingQueryParams,
  GetUserPremiumSubscribingResult,
  GetUserSubscribersQueryParams,
  GetUserSubscribersResult,
  GetUserSubscribingQueryParams,
  GetUserSubscribingResult,
  PostUserLogDownloadParams,
  PostUserLogFileViewParams,
  PostUserScreenshotParams,
  PostUserUpdateProfileParams,
  PostUserVerifyIdentityResult,
} from '../../../types/api/user';
import { AccountInfo, UploadedFile, User } from '../../../types/common';
import { completeAll } from '../../../utils/complete-all';
import { ApiService } from '../api/api.service';
import { AuthService } from '../auth/auth.service';
import { BaseService } from '../base.service';
import { SocketService } from '../socket/socket.service';
import { UserCacheService } from '../user-cache/user-cache.service';
import { UserLoaderService } from '../user-loader/user-loader.service';

enum StorageKey {
  dmUserIds = 'celebhere.user.dmUserIds',
  relatedUserIds = 'celebhere.user.relatedUserIds',
  mutingUserIds = 'celebhere.user.mutingUserIds',
  blockingUserIds = 'celebhere.user.blockingUserIds',
}

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService implements OnDestroy {
  /** 사용자 본인 정보 */
  readonly me$: Observable<User | null>;
  /** 나이 */
  readonly age$: Observable<number | null>;
  /** 연나이 */
  readonly yearAge$: Observable<number | null>;
  /** 프라이빗 대화 상대 ID 목록 */
  readonly dmUserIds$: Observable<Array<string> | null>;
  /** 연관 사용자 ID 목록 */
  readonly relatedUserIds$: Observable<Array<string> | null>;
  /** 음소거한 사용자 ID 목록 */
  readonly mutingUserIds$: Observable<Array<string> | null>;
  /** 차단한 사용자 ID 목록 */
  readonly blockingUserIds$: Observable<Array<string> | null>;

  /** 사용자 정보 업데이트용 {@link Subject} */
  private readonly updateUserSubject = new Subject<User>();

  /** 사용자 본인 정보 */
  private readonly meSubject = new BehaviorSubject<User | null>(null);
  /** 나이 */
  private readonly ageSubject = new BehaviorSubject<number | null>(null);
  /** 연나이 */
  private readonly yearAgeSubject = new BehaviorSubject<number | null>(null);

  /** 프라이빗 대화 상대 ID 목록 */
  private readonly dmUserIdsSubject = new BehaviorSubject<Array<string> | null>(null);
  private readonly refreshDmUserIdsSubject = new Subject<void>();
  private readonly refreshDmUserIdsErrorSubject = new Subject<any>();

  /** 연관 사용자 ID 목록 */
  private readonly relatedUserIdsSubject = new BehaviorSubject<Array<string> | null>(null);
  private readonly refreshRelatedUserIdsSubject = new Subject<void>();
  private readonly refreshRelatedUserIdsErrorSubject = new Subject<any>();

  /** 음소거한 사용자 ID 목록 */
  private readonly mutingUserIdsSubject = new BehaviorSubject<Array<string> | null>(null);
  private readonly refreshMutingUserIdsSubject = new Subject<void>();
  private readonly refreshMutingUserIdsErrorSubject = new Subject<any>();

  /** 차단한 사용자 ID 목록 */
  private readonly blockingUserIdsSubject = new BehaviorSubject<Array<string> | null>(null);
  private readonly refreshBlockingUserIdsSubject = new Subject<void>();
  private readonly refreshBlockingUserIdsErrorSubject = new Subject<any>();

  constructor(
    private storage: Storage,
    private translateService: TranslateService,
    private apiService: ApiService,
    private authService: AuthService,
    private socketService: SocketService,
    private userCacheService: UserCacheService,
    private userLoaderService: UserLoaderService
  ) {
    super();

    this.me$ = this.meSubject.asObservable();
    this.age$ = this.ageSubject.asObservable();
    this.yearAge$ = this.yearAgeSubject.asObservable();
    this.dmUserIds$ = this.dmUserIdsSubject.asObservable();
    this.relatedUserIds$ = this.relatedUserIdsSubject.asObservable();
    this.mutingUserIds$ = this.mutingUserIdsSubject.asObservable();
    this.blockingUserIds$ = this.blockingUserIdsSubject.asObservable();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    completeAll([
      this.updateUserSubject,
      this.meSubject,
      this.ageSubject,
      this.yearAgeSubject,
      this.dmUserIdsSubject,
      this.refreshDmUserIdsSubject,
      this.refreshDmUserIdsErrorSubject,
      this.relatedUserIdsSubject,
      this.refreshRelatedUserIdsSubject,
      this.refreshRelatedUserIdsErrorSubject,
      this.mutingUserIdsSubject,
      this.refreshMutingUserIdsSubject,
      this.refreshMutingUserIdsErrorSubject,
      this.blockingUserIdsSubject,
      this.refreshBlockingUserIdsSubject,
      this.refreshBlockingUserIdsErrorSubject,
    ]);
  }

  /** 2번 이상 호출하지 않도록 주의 */
  async init(user: User | null): Promise<void> {
    // reviewer: Promise.allSettled 로 교체했으면 함. promise all은 한개라도 실패하면 전부다 cancel하기 때문임.
    await Promise.all([
      this.storage.get(StorageKey.relatedUserIds).catch((err) => {
        console.error(err);
        return null;
      }),
      this.storage.get(StorageKey.relatedUserIds).catch((err) => {
        console.error(err);
        return null;
      }),
      this.storage.get(StorageKey.mutingUserIds).catch((err) => {
        console.error(err);
        return null;
      }),
      this.storage.get(StorageKey.blockingUserIds).catch((err) => {
        console.error(err);
        return null;
      }),
    ]).then(([dmUserIds, relatedUserIds, mutingUserIds, blockingUserIds]) => {
      if (user != null && this.authService.userId === user.id) {
        this.userCacheService.setUser(user);

        if (dmUserIds != null) {
          this.dmUserIdsSubject.next(dmUserIds);
        }
        if (relatedUserIds != null) {
          this.relatedUserIdsSubject.next(relatedUserIds);
        }
        if (mutingUserIds != null) {
          this.mutingUserIdsSubject.next(mutingUserIds);
        }
        if (blockingUserIds != null) {
          this.blockingUserIdsSubject.next(blockingUserIds);
        }
      }
    });

    // 사용자 정보 업데이트 요청 시 캐시 추가
    this.subscription = this.updateUserSubject
      .pipe(
        tap((user) => {
          this.userCacheService.setUser(user, DateTime.local().plus({ minute: 10 }));
        })
      )
      .subscribe();

    // 소켓 업데이트 반영
    this.subscription = this.socketService.fromEvent('userUpdate').subscribe((ev) => {
      const username = ev.user.celebUsername != null ? this.userCacheService.getUsername(ev.user.celebUsername) : undefined;
      const user =
        (username != null ? this.userCacheService.getUser(username) : undefined) ??
        (ev.user.id != null ? this.userCacheService.getUser(ev.user.id) : undefined);

      if (user != null) {
        const newUser: Record<string, any> = {};
        for (const key of [...new Set(Object.keys(user).concat(Object.keys(ev.user)))] as Array<keyof User>) {
          const value = ev.user[key];
          if (value === undefined) {
            newUser[key] = user[key];
          } else if (value !== null) {
            newUser[key] = value;
          }
        }
        // TODO: expiry 설정 문제 없는지 확인
        this.updateUserSubject.next(newUser as User);
      }
    });

    // 사용자 본인 정보 가져오기
    this.subscription = this.authService.userId$.pipe(switchMap((userId) => (userId != null ? this.getUser$(userId) : of(null)))).subscribe((me) => {
      this.meSubject.next(me);
    });

    // 나이, 연나이 계산
    this.subscription = this.meSubject
      .pipe(
        switchMap((me) =>
          timer(36e5 - (Date.now() % 36e5), 36e5).pipe(
            startWith(null),
            map(() => {
              const now = DateTime.local({ zone: 'Asia/Seoul' });

              if (me?.birthday == null) {
                return {
                  timestamp: now,
                  age: null,
                  yearAge: null,
                };
              }

              const birthday = DateTime.fromISO(me.birthday, { zone: 'Asia/Seoul' }).startOf('day');
              const age = Math.trunc(now.startOf('day').diff(birthday).as('year'));
              const yearAge = now.year - birthday.year;

              return {
                timestamp: now,
                age,
                yearAge,
              };
            })
          )
        )
      )
      .subscribe(({ age, yearAge }) => {
        if (this.ageSubject.value !== age) {
          this.ageSubject.next(age);
        }
        if (this.yearAgeSubject.value !== yearAge) {
          this.yearAgeSubject.next(yearAge);
        }
      });

    // 프라이빗 대화 상대 ID 목록 가져오기
    this.subscription = this.authService.userId$
      .pipe(
        switchMap((userId) =>
          userId != null
            ? this.refreshDmUserIdsSubject.pipe(
                startWith(undefined),
                concatMap(() =>
                  this.apiService.getUserDMUserIds({}).pipe(
                    map((res) => res.result.list),
                    catchError((err) => {
                      console.error(err);
                      return EMPTY;
                    })
                  )
                )
              )
            : this.refreshDmUserIdsSubject.pipe(
                startWith(undefined),
                map(() => null)
              )
        )
      )
      .subscribe((dmUserIds) => {
        this.dmUserIdsSubject.next(dmUserIds);
      });

    // 연관 사용자 ID 목록 가져오기
    this.subscription = this.authService.userId$
      .pipe(
        switchMap((userId) =>
          userId != null
            ? this.refreshRelatedUserIdsSubject.pipe(
                startWith(undefined),
                concatMap(() =>
                  this.apiService.getUserRelatedIds({}).pipe(
                    map((res) => res.result.list),
                    catchError((err) => {
                      console.error(err);
                      return EMPTY;
                    })
                  )
                )
              )
            : this.refreshRelatedUserIdsSubject.pipe(
                startWith(undefined),
                map(() => null)
              )
        )
      )
      .subscribe((relatedUserIds) => {
        this.relatedUserIdsSubject.next(relatedUserIds);
      });

    // 음소거한 사용자 ID 목록 가져오기
    this.subscription = this.authService.userId$
      .pipe(
        switchMap((userId) =>
          userId != null
            ? this.refreshMutingUserIdsSubject.pipe(
                startWith(undefined),
                concatMap(() =>
                  this.apiService.getUserMutingUserIds({}).pipe(
                    map((res) => res.result.list),
                    catchError((err) => {
                      console.error(err);
                      return EMPTY;
                    })
                  )
                )
              )
            : this.refreshMutingUserIdsSubject.pipe(
                startWith(undefined),
                map(() => null)
              )
        )
      )
      .subscribe((mutingUserIds) => {
        this.mutingUserIdsSubject.next(mutingUserIds);
      });

    // 차단한 사용자 ID 목록 가져오기
    this.subscription = this.authService.userId$
      .pipe(
        switchMap((userId) =>
          userId != null
            ? this.refreshBlockingUserIdsSubject.pipe(
                startWith(undefined),
                concatMap(() =>
                  this.apiService.getUserBlockingUserIds({}).pipe(
                    map((res) => res.result.list),
                    catchError((err) => {
                      console.error(err);
                      return EMPTY;
                    })
                  )
                )
              )
            : this.refreshBlockingUserIdsSubject.pipe(
                startWith(undefined),
                map(() => null)
              )
        )
      )
      .subscribe((blockingUserIds) => {
        this.blockingUserIdsSubject.next(blockingUserIds);
      });
  }

  /** UserService 외부에서 사용자 정보 업데이트할 수 있게 하는 메소드 */
  updateUser(user: User): void {
    this.updateUserSubject.next(user);
  }

  /**
   * 캐시된 사용자 가져오기
   *
   * @see UserCacheService.fetchUser
   */
  getCachedUser(userId: string): Observable<User | null> {
    return this.userCacheService.fetchUser(userId).pipe(map((userCache) => userCache?.user ?? null));
  }

  /**
   * @param userIdOrUsername `@`로 시작하면 username, 아니면 userId 취급
   * @param options.fallbackUnknown null 대신 알 수 없는 사용자 값 방출
   * @param options.forceRefresh 서버 요청 강제로 보냄
   */
  getUser$(
    userIdOrUsername: string,
    options?: {
      fallbackUnknown?: boolean;
      forceRefresh?: boolean;
    }
  ): Observable<User | null> {
    const { fallbackUnknown, forceRefresh } = options ?? {};

    const username = userIdOrUsername.startsWith('@') ? userIdOrUsername.slice(1) : undefined;
    const userId = username != null ? this.userCacheService.getUsername(username) : userIdOrUsername;

    // 캐시된 사용자 정보 먼저 가져옴
    return (userId != null ? this.userCacheService.fetchUser(userId) : of(null)).pipe(
      concatMap((userCache) => {
        // 강제 새로고침이 true거나 캐시된 사용자가 없거나 만료된 경우 서버에 요청
        if (forceRefresh || userCache == null || userCache.expiry <= DateTime.local()) {
          this.userLoaderService.requestUser(userIdOrUsername);
        }

        // 서버 응답과 사용자 업데이트 이벤트 모두 사용하여 지속적으로 값 방출
        return merge(
          this.userLoaderService.getUser(userIdOrUsername),
          username != null
            ? this.updateUserSubject.pipe(filter((user) => user.celebUsername === username))
            : userId != null
            ? this.updateUserSubject.pipe(filter((user) => user.id === userId))
            : NEVER
        ).pipe(
          // 캐시되어있던 정보로 시작
          startWith(userCache?.user ?? null),
          fallbackUnknown
            ? map(
                (user) =>
                  user ??
                  ({
                    id: '000000000000000000000000',
                    nickname: this.translateService.instant('USER.UNKNOWN'),
                  } satisfies User)
              )
            : tap(),
          distinctUntilChanged()
        );
      })
    );
  }

  /** 사용자 본인 정보 새로고침 */
  refreshMe(): Observable<void> {
    return this.authService.userId$.pipe(
      take(1),
      switchMap((userId) => (userId != null ? this.userLoaderService.fetchUser(userId).pipe(map(() => undefined)) : of(undefined)))
    );
  }

  /** 프라이빗 대화 상대 ID 목록 새로고침 */
  refreshDmUserIds(): Observable<void> {
    return new Observable<void>((subscriber) => {
      const sub = race(
        this.dmUserIdsSubject.pipe(
          skip(1),
          take(1),
          map(() => undefined)
        ),
        this.refreshDmUserIdsErrorSubject.pipe(
          take(1),
          concatMap((err) => throwError(() => err))
        )
      ).subscribe(subscriber);

      this.refreshDmUserIdsSubject.next();

      return sub;
    });
  }

  /** 연관 사용자 ID 목록 새로고침 */
  refreshRelatedUserIds(): Observable<void> {
    return new Observable<void>((subscriber) => {
      const sub = race(
        this.relatedUserIdsSubject.pipe(
          skip(1),
          take(1),
          map(() => undefined)
        ),
        this.refreshRelatedUserIdsErrorSubject.pipe(
          take(1),
          concatMap((err) => throwError(() => err))
        )
      ).subscribe(subscriber);

      this.refreshRelatedUserIdsSubject.next();

      return sub;
    });
  }

  /** 음소거한 사용자 ID 목록 새로고침 */
  refreshMutingUserIds(): Observable<void> {
    return new Observable<void>((subscriber) => {
      const sub = race(
        this.mutingUserIdsSubject.pipe(
          skip(1),
          take(1),
          map(() => undefined)
        ),
        this.refreshMutingUserIdsErrorSubject.pipe(
          take(1),
          concatMap((err) => throwError(() => err))
        )
      ).subscribe(subscriber);

      this.refreshMutingUserIdsSubject.next();

      return sub;
    });
  }

  /** 차단한 사용자 ID 목록 새로고침 */
  refreshBlockingUserIds(): Observable<void> {
    return new Observable<void>((subscriber) => {
      const sub = race(
        this.blockingUserIdsSubject.pipe(
          skip(1),
          take(1),
          map(() => undefined)
        ),
        this.refreshBlockingUserIdsErrorSubject.pipe(
          take(1),
          concatMap((err) => throwError(() => err))
        )
      ).subscribe(subscriber);

      this.refreshBlockingUserIdsSubject.next();

      return sub;
    });
  }

  /** 사용자 ID로 프로필 조회 API 직접 호출 */
  profileByUserId(userId: string): Observable<User>;
  profileByUserId(userId: Array<string>): Observable<Array<User>>;
  profileByUserId(userId: string | Array<string>): Observable<User | Array<User>> {
    return this.apiService.getUserProfile({ userId }).pipe(
      map((res) => (Array.isArray(userId) ? res.result.users : res.result.user)),
      tap((user) => {
        if (Array.isArray(user)) {
          for (let i = 0, len = user.length; i < len; i += 1) {
            this.updateUserSubject.next(user[i]);
          }
        } else {
          this.updateUserSubject.next(user);
        }
      })
    );
  }

  /** 사용자명으로 프로필 조회 API 직접 호출 */
  profileByUsername(username: string): Observable<User>;
  profileByUsername(username: Array<string>): Observable<Array<User>>;
  profileByUsername(username: string | Array<string>): Observable<User | Array<User>> {
    return this.apiService.getUserProfile({ username }).pipe(
      map((res) => (Array.isArray(username) ? res.result.users : res.result.user)),
      tap((user) => {
        if (Array.isArray(user)) {
          for (let i = 0, len = user.length; i < len; i += 1) {
            this.updateUserSubject.next(user[i]);
          }
        } else {
          this.updateUserSubject.next(user);
        }
      })
    );
  }

  /** 사용자 검색 */
  searchUser(search: string): Observable<Array<User>> {
    return this.apiService.getUserSearch({ nickname: search }).pipe(
      map((res) => res.result.list),
      tap((list) => {
        for (let i = 0, len = list.length; i < len; i += 1) {
          this.updateUserSubject.next(list[i]);
        }
      })
    );
  }

  /** 셀럽 목록 조회 */
  listCelebs(params: GetUserListCelebsQueryParams): Observable<GetUserListCelebsResult> {
    return this.apiService.getUserListCelebs(params).pipe(
      map((res) => res.result),
      tap((result) => {
        for (let i = 0, len = result.list.length; i < len; i += 1) {
          this.updateUserSubject.next(result.list[i]);
        }
      })
    );
  }

  /**
   * 셀럽 목록 조회
   *
   * {@link listCelebs}와 다르게 페이지네이션 있음
   */
  listCelebs2(params: GetUserListCelebs2QueryParams): Observable<GetUserListCelebs2Result> {
    return this.apiService.getUserListCelebs2(params).pipe(
      map((res) => res.result),
      tap((result) => {
        for (let i = 0, len = result.list.length; i < len; i += 1) {
          this.updateUserSubject.next(result.list[i]);
        }
      })
    );
  }

  /** 프로필용 이미지 업로드 */
  uploadProfile(file: File, for_?: 'photo' | 'backPhoto'): Observable<UploadedFile> {
    return this.apiService.postUserUploadProfile({ file, for: for_ }).pipe(map((res) => res.result.file));
  }

  /** 자신의 프로필 정보 업데이트 */
  updateProfile(userProperties: PostUserUpdateProfileParams): Observable<User> {
    return this.apiService.postUserUpdateProfile(userProperties).pipe(
      map((res) => res.result.user),
      tap((user) => this.updateUserSubject.next(user))
    );
  }

  /** 사용자 음소거 */
  muteUser(userId: string): Observable<User> {
    return this.apiService.postUserMute({ userId }).pipe(
      map((res) => res.result.user),
      tap((user) => this.updateUserSubject.next(user))
    );
  }

  /** 사용자 음소거 해제 */
  unmuteUser(userId: string): Observable<User> {
    return this.apiService.postUserUnmute({ userId }).pipe(
      map((res) => res.result.user),
      tap((user) => this.updateUserSubject.next(user))
    );
  }

  /** 사용자 차단 */
  blockUser(userId: string, reason: string, andReport?: boolean): Observable<User> {
    return this.apiService.postUserBlock({ userId, reason, andReport }).pipe(
      map((res) => res.result.user),
      tap((user) => this.updateUserSubject.next(user))
    );
  }

  /** 사용자 차단 해제 */
  unblockUser(userId: string): Observable<User> {
    return this.apiService.postUserUnblock({ userId }).pipe(
      map((res) => res.result.user),
      tap((user) => this.updateUserSubject.next(user))
    );
  }

  /** 사용자 신고 */
  reportUser(userId: string, reason: string, andBlock?: boolean): Observable<User> {
    return this.apiService.postUserReport({ userId, reason, andBlock }).pipe(
      map((res) => res.result.user),
      tap((user) => this.updateUserSubject.next(user))
    );
  }

  /** 다운로드 기록 남기기 */
  logDownload(fileId: string, params?: Omit<PostUserLogDownloadParams, 'fileId'>): Observable<void> {
    return this.authService.userId != null ? this.apiService.postUserLogDownload({ fileId, ...params }).pipe(map(() => undefined)) : EMPTY;
  }

  /** 파일 조회 기록 남기기 */
  logFileView(fileId: string, params?: Omit<PostUserLogFileViewParams, 'fileId'>): Observable<void> {
    return this.authService.userId != null ? this.apiService.postUserLogFileView({ fileId, ...params }).pipe(map(() => undefined)) : EMPTY;
  }

  /** 스크린샷 기록 남기기 */
  screenshot(params: PostUserScreenshotParams): Observable<void> {
    return this.authService.userId != null ? this.apiService.postUserScreenshot(params).pipe(map(() => undefined)) : EMPTY;
  }

  /** 계정 정보 가져오기 */
  getAccountInfo(): Observable<AccountInfo> {
    return this.apiService.getUserAccountInfo({}).pipe(map((res) => res.result.accountInfo));
  }

  /** 본인인증 결과값 등록 */
  verifyIdentity(impUid: string): Observable<PostUserVerifyIdentityResult> {
    return this.apiService.postUserVerifyIdentity({ impUid }).pipe(
      map((res) => res.result),
      tap((result) => this.updateUserSubject.next(result.user))
    );
  }

  /** 구독 */
  subscribe(celebUserId: string): Observable<User> {
    return this.apiService.postUserSubscribe({ celebUserId }).pipe(
      map((res) => res.result.user),
      tap((user) => this.updateUserSubject.next(user))
    );
  }

  /** 구독 해지 */
  unsubscribe(celebUserId: string): Observable<User> {
    return this.apiService.postUserUnsubscribe({ celebUserId }).pipe(
      map((res) => res.result.user),
      tap((user) => this.updateUserSubject.next(user))
    );
  }

  /** 구독자 목록 */
  subscribers(params: GetUserSubscribersQueryParams): Observable<GetUserSubscribersResult> {
    return this.apiService.getUserSubscribers(params).pipe(
      map((res) => res.result),
      tap((result) => {
        for (let i = 0, len = result.list.length; i < len; i += 1) {
          this.updateUserSubject.next(result.list[i]);
        }
      })
    );
  }

  /** 구독 목록 */
  subscribing(params: GetUserSubscribingQueryParams): Observable<GetUserSubscribingResult> {
    return this.apiService.getUserSubscribing(params).pipe(
      map((res) => res.result),
      tap((result) => {
        for (let i = 0, len = result.list.length; i < len; i += 1) {
          this.updateUserSubject.next(result.list[i]);
        }
      })
    );
  }

  /** 멤버십 구독자 목록 */
  premiumSubscribers(params: GetUserPremiumSubscribersQueryParams): Observable<GetUserPremiumSubscribersResult> {
    return this.apiService.getUserPremiumSubscribers(params).pipe(
      map((res) => res.result),
      tap((result) => {
        for (let i = 0, len = result.list.length; i < len; i += 1) {
          this.updateUserSubject.next(result.list[i]);
        }
      })
    );
  }

  /** 멤버십 구독 목록 */
  premiumSubscribing(params: GetUserPremiumSubscribingQueryParams): Observable<GetUserPremiumSubscribingResult> {
    return this.apiService.getUserPremiumSubscribing(params).pipe(
      map((res) => res.result),
      tap((result) => {
        for (let i = 0, len = result.list.length; i < len; i += 1) {
          this.updateUserSubject.next(result.list[i]);
        }
      })
    );
  }
}
