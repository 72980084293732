import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonError, isCommonErrorJson } from '../../../../common-error';
import * as Auth from '../../../types/api/auth';
import * as Board from '../../../types/api/board';
import * as Celeb from '../../../types/api/celeb';
import * as Chat from '../../../types/api/chat';
import * as File from '../../../types/api/file';
import * as Notice from '../../../types/api/notice';
import * as Payment from '../../../types/api/payment';
import * as Permission from '../../../types/api/permission';
import * as Point from '../../../types/api/point';
import * as Subscription from '../../../types/api/subscription';
import * as User from '../../../types/api/user';
import { DateWrapped } from '../../../types/date-wrapper';
import { paramsToQueryString } from '../../../utils';
import { unwrapDate, wrapDate } from '../../../utils/date-wrapper';
import { getFileName } from '../../../utils/get-file-name';
import { AccessTokenService } from '../access-token/access-token.service';
import { BaseService } from '../base.service';
import { DeviceInfoService } from '../device-info/device-info.service';
import { APIResponse, APIResponseFailure } from './types';

const API_URL = environment.apiUrl;

interface HeadersMap {
  [header: string]: string | string[];
}

@Injectable({
  providedIn: 'root',
})
export class ApiService extends BaseService {
  private acceptLanguage: string | null = null;
  private accessToken: string | null = null;

  private get appBuild(): string | undefined {
    return this.deviceInfoService.deviceInfo?.appBuild;
  }

  private readonly platform = Capacitor.getPlatform();

  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
    accessTokenService: AccessTokenService,
    private deviceInfoService: DeviceInfoService
  ) {
    super();

    this.subscription = combineLatest({
      langEv: translateService.onLangChange,
      defaultLangEv: translateService.onDefaultLangChange,
    })
      .pipe(
        map(({ langEv, defaultLangEv }) => ({ lang: langEv.lang, defaultLang: defaultLangEv.lang })),
        startWith({ lang: translateService.currentLang, defaultLang: translateService.defaultLang })
      )
      .subscribe(({ lang, defaultLang }) => {
        this.acceptLanguage =
          [lang, defaultLang]
            .filter((v, i, a) => v != null && a.indexOf(v) === i)
            .slice(0, 9)
            .map((value, index) => (index === 0 ? value : `${value};q=${(10 - index) / 10}`))
            .join(',') || null;
      });

    this.subscription = accessTokenService.accessToken$.subscribe((accessToken) => (this.accessToken = accessToken));
  }

  postAuthBoot(params: Auth.PostAuthBootParams) {
    return this.post<Auth.PostAuthBootResult>('/v1/auth/boot', params);
  }
  postAuthChangePassword(params: Auth.PostAuthChangePasswordParams) {
    return this.post<Auth.PostAuthChangePasswordResult>('/v1/auth/change-password', params);
  }
  postAuthDeleteAccount(params: Auth.PostAuthDeleteAccountParams) {
    return this.post<Auth.PostAuthDeleteAccountResult>('/v1/auth/delete-account', params);
  }
  getAuthResetPassword(params: Auth.GetAuthResetPasswordQueryParams) {
    return this.get<Auth.GetAuthResetPasswordResult>('/v1/auth/reset-password', params);
  }
  postAuthResetPassword(params: Auth.PostAuthResetPasswordParams) {
    return this.post<Auth.PostAuthResetPasswordResult>('/v1/auth/reset-password', params);
  }
  postAuthSendEmailCode(params: Auth.PostAuthSendEmailCodeParams) {
    return this.post<Auth.PostAuthSendEmailCodeResult>('/v1/auth/send-email-code', params);
  }
  postAuthSendPhoneNumberCode(params: Auth.PostAuthSendPhoneNumberCodeParams) {
    return this.post<Auth.PostAuthSendPhoneNumberCodeResult>('/v1/auth/send-phone-number-code', params);
  }
  postAuthSendResetPasswordLink(params: Auth.PostAuthSendResetPasswordLinkParams) {
    return this.post<Auth.PostAuthSendResetPasswordLinkResult>('/v1/auth/send-reset-password-link', params);
  }
  postAuthSignIn(params: Auth.PostAuthSignInParams) {
    return this.post<Auth.PostAuthSignInResult>('/v1/auth/sign-in', params);
  }
  postAuthSignInApple(params: Auth.PostAuthSignInAppleParams) {
    return this.post<Auth.PostAuthSignInAppleResult>('/v1/auth/sign-in-apple', params);
  }
  postAuthSignInKakao(params: Auth.PostAuthSignInKakaoParams) {
    return this.post<Auth.PostAuthSignInKakaoResult>('/v1/auth/sign-in-kakao', params);
  }
  postAuthSignOut() {
    return this.post<Auth.PostAuthSignOutResult>('/v1/auth/sign-out', {});
  }
  postAuthSignUp(params: Auth.PostAuthSignUpParams) {
    return this.post<Auth.PostAuthSignUpResult>('/v1/auth/sign-up', params);
  }
  postAuthSignUpApple(params: Auth.PostAuthSignUpAppleParams) {
    return this.post<Auth.PostAuthSignUpAppleResult>('/v1/auth/sign-up-apple', params);
  }
  postAuthSignUpKakao(params: Auth.PostAuthSignUpKakaoParams) {
    return this.post<Auth.PostAuthSignUpKakaoResult>('/v1/auth/sign-up-kakao', params);
  }
  postAuthVerifyEmailCode(params: Auth.PostAuthVerifyEmailCodeParams) {
    return this.post<Auth.PostAuthVerifyEmailCodeResult>('/v1/auth/verify-email-code', params);
  }
  postAuthVerifyPhoneNumberCode(params: Auth.PostAuthVerifyPhoneNumberCodeParams) {
    return this.post<Auth.PostAuthVerifyPhoneNumberCodeResult>('/v1/auth/verify-phone-number-code', params);
  }

  postBoardCommentDelete(params: Board.PostBoardCommentDeleteParams) {
    return this.post<Board.PostBoardCommentDeleteResult>('/v1/board/comment-delete', params);
  }
  postBoardCommentEdit(params: Board.PostBoardCommentEditParams) {
    return this.post<Board.PostBoardCommentEditResult>('/v1/board/comment-edit', params);
  }
  getBoardCommentList(params: Board.GetBoardCommentListQueryParams) {
    return this.get<Board.GetBoardCommentListResult>('/v1/board/comment-list', params);
  }
  postBoardCommentWrite(params: Board.PostBoardCommentWriteParams) {
    return this.post<Board.PostBoardCommentWriteResult>('/v1/board/comment-write', params);
  }
  postBoardPostDelete(params: Board.PostBoardPostDeleteParams) {
    return this.post<Board.PostBoardPostDeleteResult>('/v1/board/post-delete', params);
  }
  postBoardPostEdit(params: Board.PostBoardPostEditParams) {
    return this.post<Board.PostBoardPostEditResult>('/v1/board/post-edit', params);
  }
  getBoardPostList(params: Board.GetBoardPostListQueryParams) {
    return this.get<Board.GetBoardPostListResult>('/v1/board/post-list', params);
  }
  postBoardPostWrite(params: Board.PostBoardPostWriteParams) {
    return this.post<Board.PostBoardPostWriteResult>('/v1/board/post-write', params);
  }
  postBoardReportComment(params: Board.PostBoardReportCommentParams) {
    return this.post<Board.PostBoardReportCommentResult>('/v1/board/report-comment', params);
  }
  postBoardReportPost(params: Board.PostBoardReportPostParams) {
    return this.post<Board.PostBoardReportPostResult>('/v1/board/report-post', params);
  }
  postBoardUploadPhoto(params: Board.PostBoardUploadPhotoParams) {
    return this.post<Board.PostBoardUploadPhotoResult>('/v1/board/upload-photo', this.toFormData(params));
  }
  postBoardUploadVideo(params: Board.PostBoardUploadVideoParams) {
    return this.post<Board.PostBoardUploadVideoResult>('/v1/board/upload-video', this.toFormData(params));
  }

  postCelebAddBankAccount(params: Celeb.PostCelebAddBankAccountParams) {
    return this.post<Celeb.PostCelebAddBankAccountResult>('/v1/celeb/add-bank-account', this.toFormData(params));
  }
  getCelebApplicationStatus(params: Celeb.GetCelebApplicationStatusQueryParams) {
    return this.get<Celeb.GetCelebApplicationStatusResult>('/v1/celeb/application-status', params);
  }
  postCelebApply(params: Celeb.PostCelebApplyParams) {
    return this.post<Celeb.PostCelebApplyResult>('/v1/celeb/apply', params);
  }
  getCelebCelebPointBalance(params: Celeb.GetCelebCelebPointBalanceQueryParams) {
    return this.get<Celeb.GetCelebCelebPointBalanceResult>('/v1/celeb/celeb-point-balance', params);
  }
  getCelebCelebPointHistory(params: Celeb.GetCelebCelebPointHistoryQueryParams) {
    return this.get<Celeb.GetCelebCelebPointHistoryResult>('/v1/celeb/celeb-point-history', params);
  }
  postCelebEstimateSettlement(params: Celeb.PostCelebEstimateSettlementParams) {
    return this.post<Celeb.PostCelebEstimateSettlementResult>('/v1/celeb/estimate-settlement', params);
  }
  postCelebEstimateSubscription(params: Celeb.PostCelebEstimateSubscriptionParams) {
    return this.post<Celeb.PostCelebEstimateSubscriptionResult>('/v1/celeb/estimate-subscription', params);
  }
  getCelebListBankAccounts(params: Celeb.GetCelebListBankAccountsQueryParams) {
    return this.get<Celeb.GetCelebListBankAccountsResult>('/v1/celeb/list-bank-accounts', params);
  }
  getCelebMaxPrivateChats(params: Celeb.GetCelebMaxPrivateChatsQueryParams) {
    return this.get<Celeb.GetCelebMaxPrivateChatsResult>('/v1/celeb/max-private-chats', params);
  }
  postCelebMaxPrivateChats(params: Celeb.PostCelebMaxPrivateChatsParams) {
    return this.post<Celeb.PostCelebMaxPrivateChatsResult>('/v1/celeb/max-private-chats', params);
  }
  postCelebRemoveBankAccount(params: Celeb.PostCelebRemoveBankAccountParams) {
    return this.post<Celeb.PostCelebRemoveBankAccountResult>('/v1/celeb/remove-bank-account', params);
  }
  postCelebRequestSettlement(params: Celeb.PostCelebRequestSettlementParams) {
    return this.post<Celeb.PostCelebRequestSettlementResult>('/v1/celeb/request-settlement', params);
  }
  getCelebSettlementHistory(params: Celeb.GetCelebSettlementHistoryQueryParams) {
    return this.get<Celeb.GetCelebSettlementHistoryResult>('/v1/celeb/settlement-history', params);
  }
  postCelebUpdateBankAccount(params: Celeb.PostCelebUpdateBankAccountParams) {
    return this.post<Celeb.PostCelebUpdateBankAccountResult>('/v1/celeb/update-bank-account', params);
  }

  postChatCreate(params: Chat.PostChatCreateParams) {
    return this.post<Chat.PostChatCreateResult>('/v1/chat/create', params);
  }
  getChatDirectMessage(params: Chat.GetChatDirectMessageQueryParams) {
    return this.get<Chat.GetChatDirectMessageResult>('/v1/chat/direct-message', params);
  }
  postChatFavoriteMessage(params: Chat.PostChatFavoriteMessageParams) {
    return this.post<Chat.PostChatFavoriteMessageResult>('/v1/chat/favorite-message', params);
  }
  postChatInvite(params: Chat.PostChatInviteParams) {
    return this.post<Chat.PostChatInviteResult>('/v1/chat/invite', params);
  }
  postChatLeave(params: Chat.PostChatLeaveParams) {
    return this.post<Chat.PostChatLeaveResult>('/v1/chat/leave', params);
  }
  getChatListFavoritedMessage(params: Chat.GetChatListFavoritedMessageQueryParams) {
    return this.get<Chat.GetChatListFavoritedMessageResult>('/v1/chat/list-favorited-message', params);
  }
  getChatListMessage(params: Chat.GetChatListMessageQueryParams) {
    return this.get<Chat.GetChatListMessageResult>('/v1/chat/list-message', params);
  }
  getChatList(params: Chat.GetChatListQueryParams) {
    return this.get<Chat.GetChatListResult>('/v1/chat/list', params);
  }
  postChatReport(params: Chat.PostChatReportParams) {
    return this.post<Chat.PostChatReportResult>('/v1/chat/report', params);
  }
  postChatSendMessage(params: Chat.PostChatSendMessageParams) {
    return this.post<Chat.PostChatSendMessageResult>('/v1/chat/send-message', params);
  }
  postChatToggleDisabled(params: Chat.PostChatToggleDisabledParams) {
    return this.post<Chat.PostChatToggleDisabledResult>('/v1/chat/toggle-disabled', params);
  }
  postChatUnfavoriteMessage(params: Chat.PostChatUnfavoriteMessageParams) {
    return this.post<Chat.PostChatUnfavoriteMessageResult>('/v1/chat/unfavorite-message', this.toFormData(params));
  }
  postChatUploadPhoto(params: Chat.PostChatUploadPhotoParams) {
    return this.post<Chat.PostChatUploadPhotoResult>('/v1/chat/upload-photo', this.toFormData(params));
  }
  postChatUploadVideo(params: Chat.PostChatUploadVideoParams) {
    return this.post<Chat.PostChatUploadVideoResult>('/v1/chat/upload-video', this.toFormData(params));
  }

  postFileUploadBegin(params: File.PostFileUploadBeginParams) {
    return this.post<File.PostFileUploadBeginResult>('/v1/file/upload-begin', params);
  }
  postFileUploadComplete(params: File.PostFileUploadCompleteParams) {
    return this.post<File.PostFileUploadCompleteResult>('/v1/file/upload-complete', params);
  }

  getNoticeDetail(params: Notice.GetNoticeDetailQueryParams) {
    return this.get<Notice.GetNoticeDetailResult>('/v1/notice/detail', params);
  }
  getNoticeList(params: Notice.GetNoticeListQueryParams) {
    return this.get<Notice.GetNoticeListResult>('/v1/notice/list', params);
  }
  getNoticeVersion(params: Notice.GetNoticeVersionQueryParams) {
    return this.get<Notice.GetNoticeVersionResult>('/v1/notice/version', params);
  }

  postPaymentAddCard(params: Payment.PostPaymentAddCardParams) {
    return this.post<Payment.PostPaymentAddCardResult>('/v1/payment/add-card', params);
  }
  postPaymentAddCardComplete(params: Payment.PostPaymentAddCardCompleteParams) {
    return this.post<Payment.PostPaymentAddCardCompleteResult>('/v1/payment/add-card-complete', params);
  }
  getPaymentListCards(params: Payment.GetPaymentListCardsQueryParams) {
    return this.get<Payment.GetPaymentListCardsResult>('/v1/payment/list-cards', params);
  }
  postPaymentRemoveCard(params: Payment.PostPaymentRemoveCardParams) {
    return this.post<Payment.PostPaymentRemoveCardResult>('/v1/payment/remove-card', params);
  }

  getPermissionDmEnter(params: Permission.GetPermissionDmEnterQueryParams) {
    return this.get<Permission.GetPermissionDmEnterResult>('/v1/permission/dm-enter', params);
  }
  getPermissionDmSend(params: Permission.GetPermissionDmSendQueryParams) {
    return this.get<Permission.GetPermissionDmSendResult>('/v1/permission/dm-send', params);
  }

  getPointBalance(params: Point.GetPointBalanceQueryParams) {
    return this.get<Point.GetPointBalanceResult>('/v1/point/balance', params);
  }
  getPointHistory(params: Point.GetPointHistoryQueryParams) {
    return this.get<Point.GetPointHistoryResult>('/v1/point/history', params);
  }
  postPointOrder(params: Point.PostPointOrderParams) {
    return this.post<Point.PostPointOrderResult>('/v1/point/order', params);
  }
  postPointOrderComplete(params: Point.PostPointOrderCompleteParams) {
    return this.post<Point.PostPointOrderCompleteResult>('/v1/point/order-complete', params);
  }
  postPointOrderUserPaymentMethod(params: Point.PostPointOrderUserPaymentMethodParams) {
    return this.post<Point.PostPointOrderUserPaymentMethodResult>('/v1/point/order-user-payment-method', params);
  }
  getPointPointOrder(params: Point.GetPointPointOrderQueryParams) {
    return this.get<Point.GetPointPointOrderResult>('/v1/point/point-order', params);
  }
  getPointPointProducts(params: Point.GetPointPointProductsQueryParams) {
    return this.get<Point.GetPointPointProductsResult>('/v1/point/point-products', params);
  }
  postPointRefundOrder(params: Point.PostPointRefundOrderParams) {
    return this.post<Point.PostPointRefundOrderResult>('/v1/point/refund-order', params);
  }
  postPointRequestTestPoint(params: Point.PostPointRequestTestPointParams) {
    return this.post<Point.PostPointRequestTestPointResult>('/v1/point/request-test-point', params);
  }

  postSubscriptionCancelSubscriptionPremium(params: Subscription.PostSubscriptionCancelSubscriptionPremiumParams) {
    return this.post<Subscription.PostSubscriptionCancelSubscriptionPremiumResult>('/v1/subscription/cancel-subscription-premium', params);
  }
  getSubscriptionList(params: Subscription.GetSubscriptionListQueryParams) {
    return this.get<Subscription.GetSubscriptionListResult>('/v1/subscription/list', params);
  }
  postSubscriptionOrder(params: Subscription.PostSubscriptionOrderParams) {
    return this.post<Subscription.PostSubscriptionOrderResult>('/v1/subscription/order', params);
  }
  postSubscriptionOrderComplete(params: Subscription.PostSubscriptionOrderCompleteParams) {
    return this.post<Subscription.PostSubscriptionOrderCompleteResult>('/v1/subscription/order-complete', params);
  }
  getSubscriptionOrderHistory(params: Subscription.GetSubscriptionOrderHistoryQueryParams) {
    return this.get<Subscription.GetSubscriptionOrderHistoryResult>('/v1/subscription/order-history', params);
  }
  postSubscriptionOrderUserPaymentMethod(params: Subscription.PostSubscriptionOrderUserPaymentMethodParams) {
    return this.post<Subscription.PostSubscriptionOrderUserPaymentMethodResult>('/v1/subscription/order-user-payment-method', params);
  }
  getSubscriptionPremiumList(params: Subscription.GetSubscriptionPremiumListQueryParams) {
    return this.get<Subscription.GetSubscriptionPremiumListResult>('/v1/subscription/premium-list', params);
  }
  getSubscriptionProduct(params: Subscription.GetSubscriptionProductQueryParams) {
    return this.get<Subscription.GetSubscriptionProductResult>('/v1/subscription/product', params);
  }
  getSubscriptionProductList(params: Subscription.GetSubscriptionProductListQueryParams) {
    return this.get<Subscription.GetSubscriptionProductListResult>('/v1/subscription/product-list', params);
  }
  postSubscriptionRequestTestSubscription(params: Subscription.PostSubscriptionRequestTestSubscriptionPremiumParams) {
    return this.post<Subscription.PostSubscriptionRequestTestSubscriptionPremiumResult>('/v1/subscription/request-test-subscription-premium', params);
  }
  postSubscriptionRestartSubscriptionPremium(params: Subscription.PostSubscriptionRestartSubscriptionPremiumParams) {
    return this.post<Subscription.PostSubscriptionRestartSubscriptionPremiumResult>('/v1/subscription/restart-subscription-premium', params);
  }

  getUserAccountInfo(params: User.GetUserAccountInfoQueryParams) {
    return this.get<User.GetUserAccountInfoResult>('/v1/user/account-info', params);
  }
  getUserDMUsers(params: User.GetUserDMUsersQueryParams) {
    return this.get<User.GetUserDMUsersResult>('/v1/user/dm-users', params);
  }
  getUserDMUserIds(params: User.GetUserDMUserIdsQueryParams) {
    return this.get<User.GetUserDMUserIdsResult>('/v1/user/dm-user-ids', params);
  }
  getUserBlockingUsers(params: User.GetUserBlockingUsersQueryParams) {
    return this.get<User.GetUserBlockingUsersResult>('/v1/user/blocking-users', params);
  }
  getUserBlockingUserIds(params: User.GetUserBlockingUserIdsQueryParams) {
    return this.get<User.GetUserBlockingUserIdsResult>('/v1/user/blocking-user-ids', params);
  }
  postUserBlock(params: User.PostUserBlockParams) {
    return this.post<User.PostUserBlockResult>('/v1/user/block', params);
  }
  getUserListCelebs(params: User.GetUserListCelebsQueryParams) {
    return this.get<User.GetUserListCelebsResult>('/v1/user/list-celebs', params);
  }
  getUserListCelebs2(params: User.GetUserListCelebs2QueryParams) {
    return this.get<User.GetUserListCelebs2Result>('/v1/user/list-celebs-2', params);
  }
  postUserLogDownload(params: User.PostUserLogDownloadParams) {
    return this.post<User.PostUserLogDownloadResult>('/v1/user/log-download', params);
  }
  postUserLogFileView(params: User.PostUserLogFileViewParams) {
    return this.post<User.PostUserLogFileViewResult>('/v1/user/log-file-view', params);
  }
  postUserMute(params: User.PostUserMuteParams) {
    return this.post<User.PostUserMuteResult>('/v1/user/mute', params);
  }
  getUserMutingUserIds(params: User.GetUserMutingUserIdsQueryParams) {
    return this.get<User.GetUserMutingUserIdsResult>('/v1/user/muting-user-ids', params);
  }
  getUserPremiumSubscribers(params: User.GetUserPremiumSubscribersQueryParams) {
    return this.get<User.GetUserPremiumSubscribersResult>('/v1/user/premium-subscribers', params);
  }
  getUserPremiumSubscribing(params: User.GetUserPremiumSubscribingQueryParams) {
    return this.get<User.GetUserPremiumSubscribingResult>('/v1/user/premium-subscribing', params);
  }
  getUserProfile(params: User.GetUserProfileQueryParams) {
    return this.get<User.GetUserProfileResult>('/v1/user/profile', params);
  }
  postUserPushToken(params: User.PostUserPushTokenParams) {
    return this.post<User.PostUserPushTokenResult>('/v1/user/push-token', params);
  }
  getUserRelated(params: User.GetUserRelatedQueryParams) {
    return this.get<User.GetUserRelatedResult>('/v1/user/related', params);
  }
  getUserRelatedIds(params: User.GetUserRelatedIdsQueryParams) {
    return this.get<User.GetUserRelatedIdsResult>('/v1/user/related-ids', params);
  }
  postUserReport(params: User.PostUserReportParams) {
    return this.post<User.PostUserReportResult>('/v1/user/report', params);
  }
  postUserScreenshot(params: User.PostUserScreenshotParams) {
    return this.post<User.PostUserScreenshotResult>('/v1/user/screenshot', params);
  }
  getUserSearch(params: User.GetUserSearchQueryParams) {
    return this.get<User.GetUserSearchResult>('/v1/user/search', params);
  }
  getUserSettings(params: User.GetUserSettingsQueryParams) {
    return this.get<User.GetUserSettingsResult>('/v1/user/settings', params);
  }
  postUserSettings(params: User.PostUserSettingsParams) {
    return this.post<User.PostUserSettingsResult>('/v1/user/settings', params);
  }
  postUserSubscribe(params: User.PostUserSubscribeParams) {
    return this.post<User.PostUserSubscribeResult>('/v1/user/subscribe', params);
  }
  getUserSubscribers(params: User.GetUserSubscribersQueryParams) {
    return this.get<User.GetUserSubscribersResult>('/v1/user/subscribers', params);
  }
  getUserSubscribing(params: User.GetUserSubscribingQueryParams) {
    return this.get<User.GetUserSubscribingResult>('/v1/user/subscribing', params);
  }
  postUserUnblock(params: User.PostUserUnblockParams) {
    return this.post<User.PostUserUnblockResult>('/v1/user/unblock', params);
  }
  postUserUnmute(params: User.PostUserUnmuteParams) {
    return this.post<User.PostUserUnmuteResult>('/v1/user/unmute', params);
  }
  postUserUnsubscribe(params: User.PostUserUnsubscribeParams) {
    return this.post<User.PostUserUnsubscribeResult>('/v1/user/unsubscribe', params);
  }
  postUserUpdateProfile(params: User.PostUserUpdateProfileParams) {
    return this.post<User.PostUserUpdateProfileResult>('/v1/user/update-profile', params);
  }
  postUserUploadProfile(params: User.PostUserUploadProfileParams) {
    return this.post<User.PostUserUploadProfileResult>('/v1/user/upload-profile', this.toFormData(params));
  }
  postUserVerifyIdentity(params: User.PostUserVerifyIdentityParams) {
    return this.post<User.PostUserVerifyIdentityResult>('/v1/user/verify-identity', params);
  }

  private get<T>(url: string, params?: any): Observable<APIResponse<T>> {
    const headers = this.getHeaders();
    const queryString = paramsToQueryString(params);
    return this.httpClient.get<APIResponse<DateWrapped<T>>>(API_URL + url + (queryString ? '?' + queryString : ''), { headers }).pipe(
      map((res) => ({
        ...res,
        result: unwrapDate(res.result),
      })),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 0) {
            throw new Error(this.translateService.instant('ERROR.NETWORK_ERROR'));
          }
          const res = err.error as APIResponseFailure;
          if (res != null && typeof res === 'object') {
            console.log(res);
            if (isCommonErrorJson(res.error)) {
              throw new CommonError(res.error);
            } else {
              throw new Error(res.error.message);
            }
          }
        }
        throw err;
      })
    );
  }

  private post<T>(url: string, params?: any): Observable<APIResponse<T>> {
    const headers = this.getHeaders();
    return this.httpClient
      .post<APIResponse<DateWrapped<T>>>(API_URL + url, wrapDate(params), {
        headers,
      })
      .pipe(
        map((res) => ({
          ...res,
          result: unwrapDate(res.result),
        })),
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 0) {
              throw new Error(this.translateService.instant('ERROR.NETWORK_ERROR'));
            }
            const res = err.error as APIResponseFailure;
            if (res != null && typeof res === 'object') {
              console.log(res);
              if (isCommonErrorJson(res.error)) {
                throw new CommonError(res.error);
              } else {
                throw new Error(res.error.message);
              }
            }
          }
          throw err;
        })
      );
  }

  private getHeaders(): HeadersMap {
    const headers: HeadersMap = {};

    if (this.acceptLanguage) {
      headers['Accept-Language'] = this.acceptLanguage;
    }
    if (this.accessToken) {
      headers.Authorization = `Bearer ${this.accessToken}`;
    }
    if (this.platform != null) {
      headers['X-App-Platform'] = this.platform;
    }
    if (this.appBuild != null) {
      headers['X-App-Version'] = this.appBuild;
    }

    return headers;
  }

  private toFormData(params: Record<string, any>): FormData {
    const formData = new FormData();
    for (const [key, value] of Object.entries(params)) {
      if (value instanceof Blob) {
        formData.append(key, value, getFileName(value));
      } else {
        formData.append(key, value);
      }
    }
    return formData;
  }
}
