import { registerPlugin } from '@capacitor/core';

import type { EmulatorPlugin } from './definitions';
import { EmulatorWeb } from './web';

const Emulator = registerPlugin<EmulatorPlugin>('Emulator', {
  web: () => new EmulatorWeb(),
});

export * from './definitions';
export { Emulator };
