import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { concatMap, defer, finalize, firstValueFrom, from, map, Observable, of, tap } from 'rxjs';
import { OpenInAppService } from '../app/common/services/open-in-app/open-in-app.service';
import { APIErrorCode } from '../app/constants/api-error-code';
import { hasKeyExtend } from '../app/utils/has-key';
import { isAPIErrorWithCode } from '../app/utils/is-api-error-with-code';
import { AlertComponent } from './alert.component';
import { AlertButton, AlertComponentOptions, AlertInput, AlertOptions } from './alert.type';

const BACKDROP = 'backdrop';

function openAlert<T = any>(
  modalController: ModalController,
  opts: AlertOptions,
  finalizeRole: string = BACKDROP
): Observable<OverlayEventDetail<T>> {
  return defer(() => {
    let alert: HTMLIonModalElement | undefined;
    const componentProps: AlertComponentOptions = {
      header: opts.header,
      message: typeof opts.message === 'string' ? opts.message : opts.message?.value,
      inputs: opts.inputs,
      buttons: opts.buttons?.map((button) => (typeof button === 'string' ? { text: button } : button)),
    };
    const cssClass = [
      'app-alert',
      ...(opts.cssClass != null && Array.isArray(opts.cssClass) ? opts.cssClass : opts.cssClass?.split(/\s+/).filter((clas) => clas) ?? []),
    ];
    return from(
      modalController.create({
        component: AlertComponent,
        componentProps,
        cssClass,
      })
    ).pipe(
      tap((a) => (alert = a).present()),
      concatMap((a) => a.onWillDismiss<T>()),
      finalize(() => alert?.dismiss(undefined, finalizeRole))
    );
  });
}

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  /** 기본 확인 버튼 텍스트 */
  defaultOkText = '확인';
  /** 기본 취소 버튼 텍스트 */
  defaultCancelText = '취소';
  /** {@link alertError}와 {@link alertError$}에 쓰일 헤더 */
  errorHeader = '알림';

  constructor(private modalCtrl: ModalController, private openInAppService: OpenInAppService) {}

  /**
   * 알림 팝업을 표시합니다. 알림 팝업이 닫힐 때 확인 버튼이 눌렸는지 여부를 방출합니다.
   *
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param okButton 확인 버튼 텍스트
   */
  alert$(
    header: string,
    message: string,
    okButton: string | AlertButton = this.defaultOkText,
    opts?: Pick<AlertOptions, 'cssClass'>
  ): Observable<boolean> {
    return openAlert(this.modalCtrl, {
      header,
      message,
      buttons: [okButton],
      cssClass: opts?.cssClass,
    }).pipe(map((dismissDetail) => dismissDetail.role !== 'cancel' && dismissDetail.role !== 'backdrop'));
  }

  /**
   * 확인 팝업을 표시합니다. 알림 팝업이 닫힐 때 확인 버튼이 눌렸는지 여부를 방출합니다.
   *
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param okButton 확인 버튼 텍스트
   * @param cancelButton 취소 버튼 텍스트
   */
  confirm$(
    header: string,
    message: string,
    okButton: string | AlertButton = this.defaultOkText,
    cancelButton: string | AlertButton = this.defaultCancelText,
    opts?: Pick<AlertOptions, 'cssClass'>
  ): Observable<boolean> {
    const cancelButton2: AlertButton =
      typeof cancelButton === 'string'
        ? {
            text: cancelButton,
            role: 'cancel',
            fill: 'outline',
            color: 'gray-600',
          }
        : cancelButton;

    return openAlert(this.modalCtrl, {
      header,
      message,
      buttons: [cancelButton2, okButton],
      cssClass: opts?.cssClass,
    }).pipe(map((dismissDetail) => dismissDetail.role !== 'cancel' && dismissDetail.role !== 'backdrop'));
  }

  /**
   * 프롬프트 팝업을 표시합니다. 확인 팝업이 닫힐 때 입력한 데이터를 방출합니다.
   *
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param prompt 프롬프트
   * @param okButton 확인 버튼 텍스트
   * @param cancelButton 취소 버튼 텍스트
   */
  prompt$<T = string>(
    header: string,
    message: string,
    prompt: string | AlertInput,
    okButton: string | AlertButton = this.defaultOkText,
    cancelButton: string | AlertButton = this.defaultCancelText,
    opts?: Pick<AlertOptions, 'cssClass'>
  ): Observable<T | null> {
    const prompt2: AlertInput = typeof prompt === 'string' ? { name: 'value', type: 'text', placeholder: prompt } : prompt;
    const cancelButton2: AlertButton =
      typeof cancelButton === 'string'
        ? {
            text: cancelButton,
            role: 'cancel',
            fill: 'outline',
            color: 'gray-300',
          }
        : cancelButton;

    return openAlert(this.modalCtrl, {
      header,
      message,
      inputs: [prompt2],
      buttons: [cancelButton2, okButton],
      cssClass: opts?.cssClass,
    }).pipe(
      map((dismissDetail) =>
        dismissDetail.role !== 'cancel' && dismissDetail.role !== 'backdrop' ? dismissDetail.data.values[prompt2.name || 0] : null
      )
    );
  }

  /**
   * `err` 가 `Error` 인 경우 알림 팝업을 표시합니다. 그렇지 않은 경우 `console.error(err)` 를 호출합니다.
   *
   * @param err 오류
   */
  alertError$(err: unknown, opts?: Pick<AlertOptions, 'cssClass'>): Observable<void> {
    if (isAPIErrorWithCode(err, APIErrorCode.UPDATE_REQUIRED)) {
      return this.alert$('업데이트 안내', err.message, '업데이트하러 가기', opts).pipe(
        tap(() => this.openInAppService.openStore()),
        map(() => undefined)
      );
    }

    if (err instanceof Error) {
      return this.alert$(this.errorHeader, err.message).pipe(map(() => undefined));
    }

    if (
      typeof err === 'object' &&
      err != null &&
      hasKeyExtend(err, 'error') &&
      typeof err.error === 'string' &&
      hasKeyExtend(err, 'error_description') &&
      typeof err.error_description === 'string'
    ) {
      return this.alert$(this.errorHeader, err.error_description).pipe(map(() => undefined));
    }

    console.error(err);
    return of(undefined);
  }

  /**
   * 알림 팝업을 표시합니다. 알림 팝업이 닫힐 때 확인 버튼이 눌렸는지 여부를 이행합니다.
   *
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param okButton 확인 버튼 텍스트
   */
  async alert(
    header: string,
    message: string,
    okButton: string | AlertButton = this.defaultOkText,
    opts?: Pick<AlertOptions, 'cssClass'>
  ): Promise<boolean> {
    return firstValueFrom(this.alert$(header, message, okButton, opts));
  }

  /**
   * 확인 팝업을 표시합니다. 확인 팝업이 닫힐 때 확인 버튼이 눌렸는지 여부를 이행합니다.
   *
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param okButton 확인 버튼 텍스트
   * @param cancelButton 취소 버튼 텍스트
   */
  async confirm(
    header: string,
    message: string,
    okButton: string | AlertButton = this.defaultOkText,
    cancelButton: string | AlertButton = this.defaultCancelText,
    opts?: Pick<AlertOptions, 'cssClass'>
  ): Promise<boolean> {
    return firstValueFrom(this.confirm$(header, message, okButton, cancelButton, opts));
  }

  /**
   * 프롬프트 팝업을 표시합니다. 확인 팝업이 닫힐 때 입력한 데이터를 이행합니다.
   *
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param prompt 프롬프트
   * @param okButton 확인 버튼 텍스트
   * @param cancelButton 취소 버튼 텍스트
   */
  async prompt<T = string>(
    header: string,
    message: string,
    prompt: string | AlertInput,
    okButton: string | AlertButton = this.defaultOkText,
    cancelButton: string | AlertButton = this.defaultCancelText,
    opts?: Pick<AlertOptions, 'cssClass'>
  ): Promise<T | null> {
    return firstValueFrom(this.prompt$<T>(header, message, prompt, okButton, cancelButton, opts));
  }

  /**
   * `err` 가 `Error` 인 경우 알림 팝업을 표시합니다. 그렇지 않은 경우 `console.error(err)` 를 호출합니다.
   *
   * @param err 오류
   */
  async alertError(err: Error, opts?: Pick<AlertOptions, 'cssClass'>): Promise<void> {
    return firstValueFrom(this.alertError$(err, opts));
  }
}
